import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  LoginForm,
  LoginMainFooterBandItem,
  LoginPage,
  ListVariant,
} from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';
import {axios} from '../../index';
import {API_HOST} from '../../data/urls';
import { IDealerInfo } from '../../interfaces';
import { Icon, Spin, message } from 'antd';
import Select from 'react-select-virtualized';
import useAxios from 'axios-hooks';
import {ReactComponent as DriveLogo} from '../../assets/drive-logo.svg';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Button, Modal} from 'antd';

// const introText = 'The Helix DNA Marketing Automation Platform is here. We combine audiences, attribution, and automation for the automotive industry, with more than 35 million automotive customers and 280 million evaluatory data points.';

export interface LoginProps {
  loading?: boolean;
  partners?: IDealerInfo[];
  setApiKey?: (v: string) => void;
  setUcid?: (v: string) => void;
  setToken?: (v: string) => void;
  token?:any;
  error?:any;
  handleLogout:(v:any)=>void
}

export function Login({ loading, partners, setApiKey, setUcid, setToken, token, error, handleLogout }: LoginProps) {
  const [{ data: loginImageUrl }] = useAxios<string>(`https://${API_HOST}/?do=getLoginImage`);
  const [forgotPassFlow, setForgotPassFlow] = React.useState(false);
  const [showHelperText, setShowHelperText] = React.useState(false);
  const [usernameValue, setUsernameValue] = React.useState('');
  const [isValidUsername, setIsValidUsername] = React.useState(true);
  const [passwordValue, setPasswordValue] = React.useState('');
  const [isValidPassword, setIsValidPassword] = React.useState(true);
  const [isRememberMeChecked, setIsRememberMeChecked] = React.useState(false);
  const hasPartners = !!partners?.length;
  const [getClientError, setgetClientErrore] = React.useState<any>('');
  const [partnersDisplay, setpartnersDisplay] = React.useState(false);
  const [modalStatus, setModalStatus] = React.useState(false);
  const [popMessage, setPopMessage] = React.useState('');
   

  //const location = useLocation();
  //const queryParams = new URLSearchParams(location.search);

  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.hash.split('?')[1]);
 
  const myParam = queryParams.get('token');
  const hasMyParam = queryParams.has('token');
  const ucidParam:any = queryParams.get('ucid');
  const ucidHasParam = queryParams.has('ucid');
  const legacyidParam:any = queryParams.get('legacyId');
  const legacyidHasParam = queryParams.has('legacyId');
  const entityidParam:any = queryParams.get('entityId');
  const entityidHasParam = queryParams.has('entityId');
  const gsmOrgidParam:any = queryParams.get('gsmOrganization');
  const gsmOrgidHasParam = queryParams.has('gsmOrganization');
  const history = useHistory();
  useEffect(()=>{
    const fetchData = async () => {
      try {
          if(hasMyParam && myParam!=''){
              const response = await axios.get(`https://${API_HOST}?do=checkLogin`,{
                headers: {
                  'Authorization': `Bearer ${myParam}`
                }
              });
              if (response.data.hasOwnProperty('token')){
                  setToken?.(response.data.token);
                  // if(ucidHasParam && ucidParam!=''){
                  //   setUcid?.(ucidParam);
                  // }else 
                  if(legacyidHasParam || entityidHasParam || gsmOrgidHasParam || ucidHasParam){
                    console.log("==2",legacyidHasParam,entityidHasParam,gsmOrgidHasParam,ucidHasParam);
                    try {
                        let param = (legacyidHasParam?'legacyId':(entityidHasParam?'entityId':(gsmOrgidHasParam?'gsmOrganization':(ucidHasParam?'ucid':''))))
                        let value = (legacyidHasParam?legacyidParam:(entityidHasParam?entityidParam:(gsmOrgidHasParam?gsmOrgidParam:(ucidHasParam?ucidParam:''))))
                        const responseClient = await axios.get(`https://${API_HOST}?do=getClientIds&`+ param + "=" + value,{
                          headers: {
                            'Authorization': `Bearer ${myParam}`
                          }
                        });
                        if (responseClient.status === 200 && responseClient.data.hasOwnProperty('ucid') && responseClient.data.ucid!='' && responseClient.data.ucid!=null){
                          setUcid?.(responseClient.data.ucid);
                          setpartnersDisplay(true);
                        }
                    } catch (error) {
                          console.log("==4",error);
                          setgetClientErrore(error);
                          console.error('Error fetching data:', error);
                    }
                  }else{
                    console.log("==else");
                    setpartnersDisplay(true);
                  }
                  console.log("==5");
                  window.history.replaceState({}, document.title, window.location.pathname);
                  //history.push("/");
                  //window.location.reload();
              }else{
                  handleLogout('source');
              }
          }else{
            if(token=='' || token==0 || token==null || `${error}`.includes('401')){
                handleLogout('source');
            }
          }
      } catch (error) {
            console.error('Error fetching data:', error);
            handleLogout('source');
      }
    };
    fetchData();
  },[error]);

  useEffect(()=>{ 
    if(getClientError?.response?.status==404 || getClientError?.response?.status==403){
      if(hasPartners){
        setModalStatus(true);
        setPopMessage("This store is NOT setup for Drive. Please contact support@drivehelix.com or Please select one of the following stores in the drop down list of all stores.");
        setpartnersDisplay(true);
      }else{
        setModalStatus(true);
        setPopMessage("This store is NOT setup for Drive. Please contact support@drivehelix.com");
      }
           
    }
  },[getClientError,loading])


  const handleCloseConfirm = () => {
    if(hasPartners){
      setModalStatus(false);
    }else{
      history.go(-3);
    }
  };
 

  const onLoginButtonClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const validUsername = !!usernameValue;
    const validPassword = !!passwordValue;
    setIsValidUsername(validUsername);
    setIsValidPassword(validPassword);
    setShowHelperText(!usernameValue || !passwordValue);

    if (validUsername && validPassword) {
      try {
        const response = await axios.post(`https://${API_HOST}?do=login`,`${JSON.stringify({userName:usernameValue,password:passwordValue})}`);
        if (response.data.hasOwnProperty('apiKey'))
            setApiKey?.(response.data.apiKey);
        if (response.data.hasOwnProperty('token'))
            setToken?.(response.data.token);
      } catch(e) {
        setIsValidUsername(false);
        setIsValidPassword(false);
        setShowHelperText(true);
      }
    }
  };

  const onForgotPassClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setForgotPassFlow(true);
    setIsValidUsername(!!usernameValue);
    setShowHelperText(!usernameValue);

    if (usernameValue) {
      try {
        await axios.get(`https://${API_HOST}?do=forgotPassword&email=${usernameValue}`);
        message.success('Password reset link sent! Please check your email.');
      } catch(e) {
        message.error('Oops! Couldn\'t send password reset link. Please try again.');
      }
    }
  };

  const onPartnerSelect = (option: any) => setUcid?.(option.value);

  const helperText = forgotPassFlow ? (
    <React.Fragment>
      &nbsp;Please enter username or email before resetting password.
    </React.Fragment>
  ) : (
    <React.Fragment>
      &nbsp;Invalid login credentials.
    </React.Fragment>
  );

  const forgotCredentials = (
    <LoginMainFooterBandItem>
      <a href="#" onClick={onForgotPassClick}>Forgot password?</a>
    </LoginMainFooterBandItem>
  );

  // const listItem = (
  //   <React.Fragment>
  //     <ListItem>
  //       <LoginFooterItem href="#">Terms of Use</LoginFooterItem>
  //     </ListItem>
  //     <ListItem>
  //       <LoginFooterItem href="#">Help</LoginFooterItem>
  //     </ListItem>
  //     <ListItem>
  //       <LoginFooterItem href="https://drivehelix.com/privacy.php">Privacy Policy</LoginFooterItem>
  //     </ListItem>
  //   </React.Fragment>
  // );

  const loginForm = (
    <LoginForm
      showHelperText={showHelperText}
      helperText={helperText}
      helperTextIcon={<ExclamationCircleIcon />}
      usernameLabel="Username"
      usernameValue={usernameValue}
      onChangeUsername={setUsernameValue}
      isValidUsername={isValidUsername}
      passwordLabel="Password"
      passwordValue={passwordValue}
      isShowPasswordEnabled
      onChangePassword={setPasswordValue}
      isValidPassword={isValidPassword}
      isRememberMeChecked={isRememberMeChecked}
      onChangeRememberMe={() => setIsRememberMeChecked(prevState => !prevState)}
      onLoginButtonClick={onLoginButtonClick}
    />
  );

  return (
    
    <LoginPage
      footerListVariants={ListVariant.inline}
      loginTitle=""
      //forgotCredentials={loading || hasPartners ? undefined : forgotCredentials}
      className="login-page"
      backgroundImgSrc={loginImageUrl}
      backgroundImgAlt="Login Background Image"
    >
      <DriveLogo className="login-drive-logo" />
      {loading ? (
        <div className="loading-spinner">
          <Spin  size="large" indicator={<Icon type="loading" spin />} />
        </div>
      ) : hasPartners && partnersDisplay==true ? (
        <>
          <p className="login-context-text">Select a partner</p>
          <Select
            className="login-autocomplete"
            options={partners?.map(p => ({label: `${p.partnerName} (${p.ucid})`, value: p.ucid}))}
            onChange={onPartnerSelect}
            isClearable={false}
          />
        </>
      ) : (
        <>
          {/* <p className="login-context-text">Welcome</p>
          {loginForm} */}
        </>
      )}
       <Modal
            className="success-modal-root login-alert "
            visible={modalStatus}
            title="Alert"
            onCancel={handleCloseConfirm}
            closable={partnersDisplay}
            footer={(
              <Button
                type="primary"
                onClick={handleCloseConfirm}
              >
               {partnersDisplay?'Close':'Go Back'}
              </Button>
            )}
            width={300}
          >
          <p>
            {popMessage}
          </p>
      </Modal>
       
    </LoginPage>
  );
}
